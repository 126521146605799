import "core-js/stable";
import "regenerator-runtime/runtime";

// detect webp support and add modifier class to HTML element if so (for CSS background-image use)
const webP = new Image();
webP.onload = () => {
    document.querySelector('html').classList.add('supports-webp');
};
webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';


import "./js/config.js";

import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Load in styles
import "./scss/styles.scss";

// Conditional dynamic imports

// Load in custom JS modules required on initial page load

import('./js/viewport-size');
import('./js/scrolling');
import('./js/keyboard-accessibility-check');

if (document.querySelector('.hero--video') != null) {
    import('./js/hero-video.js');
} else {
    document.body.classList.remove('initial-view');
}

// ensure page starts from top if refreshed or reloaded
window.addEventListener('beforeunload', () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'instant'
    });
});

if (document.querySelector('.elvis-signature') != null) {
    import('./modules/elvis-signature/elvis-signature.js');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`, '');
    });
}

const jsModuleCheck = async () => {

    let testQuery = null;

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // smooth-scroll-link
    testQuery = document.querySelector('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
        addDataJsParsed(thisDomQuery, "smooth-scroll-link");
        import('./js/smooth-scroll-link').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // attractions-swiper
    testQuery = document.querySelector('.attractions-swiper:not([data-js-parsed--attractions-swiper])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.attractions-swiper:not([data-js-parsed--attractions-swiper])');
        addDataJsParsed(thisDomQuery, "attractions-swiper");
        import('./modules/attractions-swiper/attractions-swiper.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
        
    // tilt-fx
    testQuery = document.querySelector('.tilt-fx:not([data-js-parsed--tilt-fx])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.tilt-fx:not([data-js-parsed--tilt-fx])');
        addDataJsParsed(thisDomQuery, "tilt-fx");
        import('./js/tilt-fx').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
        
    // text-line-reveal
    testQuery = document.querySelector('[data-text-line-reveal]:not([data-js-parsed--text-line-reveal])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('[data-text-line-reveal]:not([data-js-parsed--text-line-reveal])');
        addDataJsParsed(thisDomQuery, "text-line-reveal");
        import('./js/text-line-reveal').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // marquee-scroller
    testQuery = document.querySelector('.marquee-scroller:not([data-js-parsed--marquee-scroller])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.marquee-scroller:not([data-js-parsed--marquee-scroller])');
        addDataJsParsed(thisDomQuery, "marquee-scroller");
        import('./modules/marquee-scroller/marquee-scroller.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
    
    // bokeh-sparkle
    testQuery = document.querySelector('.bokeh-sparkle:not([data-js-parsed--bokeh-sparkle])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.bokeh-sparkle:not([data-js-parsed--bokeh-sparkle])');
        addDataJsParsed(thisDomQuery, "bokeh-sparkle");
        import('./js/bokeh-sparkle').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // .klaviyo_form_trigger
    testQuery = document.querySelector('.klaviyo_form_trigger:not([data-js-parsed--klaviyo_form_trigger])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.klaviyo_form_trigger:not([data-js-parsed--klaviyo_form_trigger])');
        addDataJsParsed(thisDomQuery, "klaviyo_form_trigger");
        import('./js/klaviyo-form-trigger').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
        
    // tripadvisor icon shiv
    if (document.querySelector('.fa-tripadvisor') != null) {
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tripadvisor'));
        });
    }
    
    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if (targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if (checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}